import React, { useState } from 'react';
import CustomModal from '../component/Modal';
import axios from 'axios';

const Quote = ({ openQoute, toggleQuote }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    contact: '',
    company: '',
    service: '',
    message: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feedback, setFeedback] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFeedback('');

    try {
      const response = await axios.post('https://bcs-mailer.vercel.app/send-email', formData);
      setFeedback('Your message has been sent successfully!');
      setFormData({
        fullName: '',
        email: '',
        contact: '',
        company: '',
        service: '',
        message: '',
        type: 2,
      });
    } catch (error) {
      setFeedback('Failed to send your message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <CustomModal isOpen={openQoute} toggleOpen={toggleQuote}>
      <form
        onSubmit={handleSubmit}
        className="border border-[#CBCBCB] px-8 pb-8 pt-6 bg-white rounded-0 h-screen flex flex-col justify-center"
      >
        <div className="flex justify-end cursor-pointer" onClick={toggleQuote}>
          <img src={'/images/close.svg'} alt="icon" />
        </div>
        <div className="pb-3 border-b border-dashed border-[#D7D7D7] mb-5">
          <h3 className="text-primary font-semibold text-[20px] font-mn_semibold">Get Quote</h3>
        </div>
        <div className="flex flex-col gap-y-4">
          <div>
            <label className="text-[#868686] font-medium text-[14px] w-full" htmlFor="fullName">
              Full name
            </label>
            <input
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              className="border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-1 focus:outline-none focus:border-primary"
              placeholder="Full Name"
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-2">
            <div>
              <label className="text-[#868686] font-medium text-[14px] w-full" htmlFor="email">
                Email Address
              </label>
              <input
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                type="email"
                className="border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-1 focus:outline-none focus:border-primary"
                placeholder="account@email.com"
                required
              />
            </div>
            <div>
              <label className="text-[#868686] font-medium text-[14px] w-full" htmlFor="contact">
                Contact Number
              </label>
              <input
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={handleChange}
                className="border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-1 focus:outline-none focus:border-primary"
                placeholder="Phone Number"
                required
              />
            </div>
          </div>
          <div>
            <label className="text-[#868686] font-medium text-[14px] w-full" htmlFor="company">
              Company Name (Optional)
            </label>
            <input
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              className="border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-1 focus:outline-none focus:border-primary"
              placeholder="Name of company"
            />
          </div>
          <div>
            <label className="text-[#868686] font-medium text-[14px] w-full" htmlFor="service">
              Type of Service
            </label>
            <select
              id="service"
              name="service"
              value={formData.service}
              onChange={handleChange}
              className="border border-[#CBCBCB] rounded-[10px] p-3 w-full mt-1 focus:outline-none focus:border-primary"
              required
            >
              <option value="">Select Option</option>
              <option>Market Entry Strategy</option>
              <option>Competitive Positioning</option>
              <option>Value Proposition</option>
              <option>Financial Modelling</option>
              <option>Identifying and Assessing Digital Opportunities</option>
              <option>Organizational Restructuring</option>
              <option>Process Optimization</option>
              <option>Cost Reduction Strategy</option>
              <option>Tailored Innovation Solutions</option>
            </select>
          </div>
          <div>
            <label className="text-[#868686] font-medium text-[14px] w-full" htmlFor="message">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows={7}
              className="border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-1 focus:outline-none focus:border-primary"
              placeholder="Message"
              required
            />
          </div>
          <div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="flex-shrink-0 bg-primary text-white rounded-[7px] px-5 py-3 font-medium"
            >
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </button>
          </div>
          {feedback && <p className="mt-3 text-center text-primary">{feedback}</p>}
        </div>
      </form>
    </CustomModal>
  );
};

export default Quote;
