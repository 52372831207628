import React, {useState} from 'react'
import { Fade, Slide } from 'react-awesome-reveal'
import axios from 'axios';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        contact: '',
        message: '',
      });
    
      const [loading, setLoading] = useState(false);
      const [success, setSuccess] = useState('');
      const [error, setError] = useState('');
    
      const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevData) => ({
          ...prevData,
          [id]: value,
        }));
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setSuccess('');
        setError('');
    
        const payload = {
          fullName: `${formData.firstName} ${formData.lastName}`,
          email: formData.email,
          contact: formData.contact,
          company: formData.company,
          service: '', // Since service is not in the form, set it to an empty string
          message: formData.message,
          type: 1
        };
    
        try {
          const response = await axios.post('https://bcs-mailer.vercel.app/send-email', payload);
          if (response.status === 200) {
            setSuccess('Your message has been sent successfully!');
            setFormData({
              firstName: '',
              lastName: '',
              email: '',
              company: '',
              contact: '',
              message: '',
            });
          }
        } catch (err) {
          console.error(err);
          setError('An error occurred while sending your message. Please try again.');
        } finally {
          setLoading(false);
        }
      };

    return (
        <section className="py-10 bg-white sm:py-16 lg:py-10">
            <div className="w-11/12 mx-auto">
                <div className='block lg:grid grid-cols-12 gap-8  mb-10'>
                    <div className='col-span-12 lg:col-span-5 mb-8 lg:mb-0'>
                        <Slide>

                            <p className='text-primary text-[13px] font-normal font-dmsans mb-5'>GET IN TOUCH</p>
                            <h2 className=" text-[32px] leading-[38.82px] md:leading-[54.59px] text-[#353535] md:text-[45px]  font-mn_semibold">Ready to transform your business today?</h2>
                            <p className="leading-[20.83px]  mt-5 text-[16px] font-normal text-[#353535] font-dmsans mb-8  ">Have questions, comments, or inquiries about our consulting services? We're here to help. </p>
                            <div className='bg-[#F6F6F6] p-5'>
                                <Fade cascade>

                                    <div className='flex  gap-3 pb-5 border-b border-dashed border-[#B5B8C1]'>
                                        <div className='mt-1'>
                                            <img src={'/images/location.svg'} alt='icon' />
                                        </div>
                                        <div className='flex-col flex gap-3'>
                                            <p className='font-dmsans'>
                                                Drop us an email at</p>
                                            <div className='font-dmsans'>
                                            <a href="mailto:info@bayaconsult.com" className='text-primary  font-bold'>info@bayaconsult.com</a>
                                                <p>or</p>
                                                <a href="mailto:oluwatobi.okeyinka@bayaconsult.com" className='text-primary  font-bold'>oluwatobi.okeyinka@bayaconsult.com</a>

                                            </div>
                                        </div>


                                    </div>
                                    <div className='flex  gap-3 py-5 border-[#B5B8C1]'>
                                        <div className='mt-2'>
                                            <img src={'/images/phone.svg'} alt='icon' />
                                        </div>
                                        <p className='font-dmsans'>
                                            Prefer to speak directly to one of our consultants?
                                            <br />
                                            Give us a call at <a href="tel:+234 810 260 6586" className='text-primary  font-bold'>+234 810 260 6586</a> during our <br />business hours:<br />
                                            <span className='text-primary  font-bold'> Monday to Friday, 9:00 AM - 5:00 PM.</span>
                                        </p>

                                    </div>

                                    <div className='flex items-center gap-3 pt-5 hidden '>
                                        <div>
                                            <img src={'/images/location.svg'} alt='icon' />
                                        </div>
                                        <p className='font-dmsans'>
                                            or visit us at our office located at:
                                            <br />
                                            <span className='text-primary  font-bold'>123 Consulting Street Suite 101, City, State, ZIP Code
                                                <br />
                                                Country</span>
                                        </p>

                                    </div>
                                </Fade>


                            </div>
                        </Slide>

                    </div>
                    <div className="col-span-12 lg:col-span-7">
            <Slide direction="right">
              <form className="border border-[#CBCBCB] p-5 md:p-8 rounded-[25px]" onSubmit={handleSubmit}>
                <div className="grid grid-cols-2 mb-3 gap-3">
                  <div>
                    <label className="text-[#868686] font-medium text-[14px]" htmlFor="firstName">
                      First Name
                    </label>
                    <input
                      id="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      className="border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-2 focus:outline-none focus:border-primary"
                      placeholder="First Name"
                      required
                    />
                  </div>
                  <div>
                    <label className="text-[#868686] font-medium text-[14px]" htmlFor="lastName">
                      Last Name
                    </label>
                    <input
                      id="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      className="border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-2 focus:outline-none focus:border-primary"
                      placeholder="Last Name"
                      required
                    />
                  </div>
                </div>
                <div className="mb-3">
                  <label className="text-[#868686] font-medium text-[14px]" htmlFor="email">
                    Email Address
                  </label>
                  <input
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-2 focus:outline-none focus:border-primary"
                    placeholder="account@email.com"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="text-[#868686] font-medium text-[14px]" htmlFor="contact">
                    Contact Number
                  </label>
                  <input
                    id="contact"
                    value={formData.contact}
                    onChange={handleChange}
                    className="border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-2 focus:outline-none focus:border-primary"
                    placeholder="Contact Number"
                    required
                  />
                </div>
                <div className="mb-3">
                  <label className="text-[#868686] font-medium text-[14px]" htmlFor="company">
                    Company Name (Optional)
                  </label>
                  <input
                    id="company"
                    value={formData.company}
                    onChange={handleChange}
                    className="border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-2 focus:outline-none focus:border-primary"
                    placeholder="Name of company"
                  />
                </div>
                <div className="mb-4">
                  <label className="text-[#868686] font-medium text-[14px]" htmlFor="message">
                    Type Message
                  </label>
                  <textarea
                    rows={7}
                    id="message"
                    value={formData.message}
                    onChange={handleChange}
                    className="border border-[#CBCBCB] rounded-[10px] placeholder:text-[14px] placeholder:font-normal p-3 w-full mt-2 focus:outline-none focus:border-primary"
                    placeholder="Your message here"
                    required
                  />
                </div>
                <div>
                  <button
                    type="submit"
                    className="flex-shrink-0 bg-primary text-white rounded-[7px] px-5 py-3 font-medium"
                    disabled={loading}
                  >
                    {loading ? 'Sending...' : 'Send Message'}
                  </button>
                </div>
                {success && <p className="text-green-500 mt-3">{success}</p>}
                {error && <p className="text-red-500 mt-3">{error}</p>}
              </form>
            </Slide>
          </div>
                </div>

            </div>

        </section>
    )
}

export default ContactForm